<template>
  <!-- Footer -->
  <footer class="-rn-footer" id="footer">
   

    <FooterTwo />
  </footer>
  <!--// Footer -->
</template>

<script>
  import FooterTwo from "./FooterTwo";
  export default {
    components: {
      FooterTwo,
    },
    data() {
      return {
        quickLink: [
          // {
          //   name: "Blog",
          //   RouteName: "/blog",
          // },
          {
            name: "Servicios",
            RouteName: "service",
          },
          {
            name: "Portfolio",
            RouteName: "portfolio",
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
